import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import { Layout } from '../components/Layout';
import { Heading } from '../components/Headings';
import { colors, fonts } from '../components/utilities/theme';
import { Icon } from '../components/svg/icon';
import { GatsbyImage } from '@utilities/gatsby-image';

const About = () => {
  const data = useStaticQuery(graphql`
    {
      michael: file(relativePath: { eq: "michael-measuring.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      patrick: file(relativePath: { eq: "patrick-screen-print.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Layout page="about">
        <Grid>
          <SectionOnePic
            fluid={data.michael.childImageSharp.fluid}
            alt="michael measuring shirt for vinyl"
          />
          <SectionOneText>
            <Heading behindText="eon studios">michael schenk</Heading>
            <Paragraph>
              Michael Schenk "is" Eon Studios. He started the business and has
              been the sole proprietor since 1984. Michael still does all the
              artwork and preparation, while his assistant, Patrick Houston, has
              given Michael a break and handles much of the production. They
              attend local and national trade shows every year to keep ahead of
              current and popular trends in art layout, apparel and
              printing/embroidery technology.
            </Paragraph>
          </SectionOneText>
          <SectionTwoText>
            <IconHeading>
              <Icon scale={1.5} name="history" />
              history
            </IconHeading>
            <ParagraphTwo>
              Eon Studios Screenprint started as a home based operation way back
              in 1984. Since then, we have moved twice, finding our current
              location in 1990 at the corner of Cache La Poudre and Institute
              Street. While the building has its own history we have been here
              for over 30 years making a name for ourselves.
            </ParagraphTwo>
          </SectionTwoText>
          <SectionTwoPic
            fluid={data.patrick.childImageSharp.fluid}
            alt="patrick screen printing"
          />
        </Grid>
      </Layout>
    </motion.div>
  );
};

export default About;

const Grid = styled.div`
  justify-self: center;
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  grid-template-areas:
    'section-one-pic section-one-text'
    'section-two-text section-two-pic';
  z-index: 10;
  margin: 15vh 0;
  row-gap: 20vh;

  @media (max-width: 900px) {
    margin: 20vh 0;
    grid-template-columns: 1fr;
    grid-template-areas:
      'section-one-text'
      'section-one-pic'
      'section-two-text'
      'section-two-pic';
    justify-content: center;
  }

  @media (max-width: 900px) and (orientation: landscape) {
    margin: 30vh 0;
  }
`;

const SectionOnePic = styled(Img)<GatsbyImage>`
  grid-area: section-one-pic;
  max-width: 600px;
`;

const SectionOneText = styled.section`
  margin-left: 85px;
  grid-area: section-one-text;
  max-width: 600px;

  @media (max-width: 900px) {
    margin-left: 0;
  }
`;

const Paragraph = styled.p`
  font-family: ${fonts.secondary};
  font-size: 24px;
  line-height: 1.75;
`;

const SectionTwoPic = styled(Img)<GatsbyImage>`
  grid-area: section-two-pic;
  max-width: 600px;
`;

const SectionTwoText = styled.section`
  grid-area: section-two-text;
  background: ${colors.black};
  width: 100%;
  padding: 35px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  max-width: 600px;
`;

const IconHeading = styled.h2`
  font-family: ${fonts.primary};
  font-size: 64px;
  color: white;
  font-weight: 500;
  text-transform: uppercase;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  column-gap: 25px;
  align-items: center;
`;

const ParagraphTwo = styled(Paragraph)`
  color: white;
`;
